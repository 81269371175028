import React from 'react';
import './App.css';
import NavBar from '..//src/components/navBar/navBar';
import Home from "../src/components/home/home";
import Footer from "../src/components/footer/footer.js";

export class  App extends React.Component {
  render() {
    return (
      <div className="App container">
        <header className="App-header">
          <NavBar/>
        </header>
        <Home/>
      </div>
  );
}}

export default App;
