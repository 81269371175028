import React from "react";
import "./navBar.css";
import logo_erika from "../pictures/logo_erika.png";
import logo_mobile from "../pictures/mobile_logo.jpg";
// import spanish_flag from "../pictures/spanish_flag.png";
// import english_flag from "../pictures/english_flag.png";
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu} from 'reactstrap';

export class NavBar extends React.Component {   
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false
        };
      }
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
    
    render() {     
           return (
            <div className="navbar-wrapper">
                <Navbar dark expand="md">
                    <a className="navbar-brand" href="#">
                        <img className="logo" alt="" src={logo_erika}/>
                        <img className="mobilelogo" alt="" src={logo_mobile}/>
                    </a>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ms-auto" navbar>   
                         <NavItem>
                            <NavLink href="#menu">Menu</NavLink>
                        </NavItem> 
                        <NavItem>
                            <NavLink href="#gallery">Gallery</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#reserve">Reserve</NavLink>
                        </NavItem>
                        
                        <NavItem>
                            <NavLink href="#contact">Contact</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/"></NavLink>
                        </NavItem>
                    </Nav>            
                    </Collapse>
                </Navbar>
      

            </div>       
           
                                        


        )

        }
    }
export default NavBar;    
    