import React from "react";
import "./footer.css";
import fb from "../pictures/fb.png"

export class Footer extends React.Component {
    render() {
        return(

            <div className="row">
                <div>
                    <p className= "infoFooter">Calle Olleria 20, Fuengirola | <a href="tel:655 89 66 98" className="footerLink">655 89 66 98</a> | <a className="" href="https://www.facebook.com/search/top?q=christy%27s%20irish%20gastropub%20fuengirola">
                        <img className="FB" alt="Facebook" src={fb}/></a></p> 

                
                </div>
            </div>






        )
    }
}

export default Footer;
