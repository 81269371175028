import React from "react";
import "./home.css";
import plate from "../pictures/plate.jpg";
import cake from "../pictures/cake.jpg";
import burger from "../pictures/burger.png";
import ribs from "../pictures/ribs.png";
import coffe from "../pictures/coffe.png";
import mushrooms from "../pictures/mushrooms.jpg";
import panncake from "../pictures/panncake.jpg";
import rollitos from "../pictures/rollitos.png";
import cheese from "../pictures/cheese.jpeg";
import drinks from "../pictures/drinks.jpeg";
import Footer from "../footer/footer.js";
import Map from './map';
import ReservasEn from "../reservas/reservas_en";

const location = {
    address: 'Christy´s Irish Gastropub, C. Olleria, 20, 29640 Fuengirola, Málaga',
    lat: 36.540295639781846,
    lng: -4.62125736932157
  }

export class Home extends React.Component {

    getContactUs() {
        
            return 
    }

    render() {  
        
        let contactUs = this.getContactUs();
    
        return(
            <div>
                <div className="front_picture"></div>

            <div className="linearGradient"> 

                <div className="welcomeBorder">
                    <h1 className="header"> Welcome to Christy´s Irish Gastropub!</h1>
                    <p className="welcomeText">Christy's Irish Gastropub is owned and operated by CHRISTY, ex manager of Restaurant Aroma and Pub O'brien in Fish Alley.
                    It's the ideal place for the perfect pint of Guinness, an Irish coffee or a plate of home cooked food with an Irish twist....</p>
                </div>            
            
            <div className="row menu" id="menu"> 

                <h2 className="col-12 header1">Small plates</h2>

                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-md-6 columnSetting">
                    
                        {/* <div className="row"> 
                            <div className="col-10"><span className="text">Today´s soup</span></div><br/>
                            <div className="col-2 price">7,50</div>
                            <div className="menuDescrip">Pumpkin with parmesan crisps- ask Christy</div>
                        </div> */}

                        <div className="row" > 
                            <div className="col-10 columnSetting"><span className="menuHeader">Garlic bread</span></div><br/>
                            <div className="col-2 price">3€/4€</div>
                            <div className="menuDescrip columnSetting">Plain or cheesy</div>
                        </div>

                        <div className="row"> 
                            <div className="col-10 columnSetting"><span className="menuHeader">Crispy sticky pork & vegetable spring rolls</span></div><br/>
                            <div className="col-2 price">7€</div>
                            <div className="menuDescrip columnSetting">Served with bitter sweet plum sauce</div>
                        </div>  

                        <div className="row"> 
                            <div className="col-10 columnSetting"><span className="menuHeader">Stuffed breaded mushrooms</span></div><br/>
                            <div className="col-2 price">8€</div>
                            <div className="menuDescrip columnSetting">Served with homemade alioli</div>
                        </div>                                             
                    </div>
                    

                    <div className="col-lg-6 col-sm-6 col-md-6 columnSetting">                                             

                        <div className="row"> 
                            <div className="col-10 columnSetting"><span className="menuHeader">Fresh langoustines in thin filo Thai style</span></div><br/>
                            <div className="col-2 price">7€/14€</div>
                            <div className="menuDescrip columnSetting">Served with chili sauce</div>
                        </div>

                        <div className="row"> 
                            <div className="col-10 columnSetting">Fresh cut chips with garlic sauce & cheese</div>
                            <div className="col-2 price">5.50€</div>
                        </div>                         

                        <div className="row"> 
                            <div className="col-10 columnSetting"><span className="menuHeader">Chicken liver & Cognac</span></div><br/>
                            <div className="col-2 price">8€</div>
                            <div className="menuDescrip columnSetting">Pate with Irish soda bread</div>
                        </div>   

                        <div className="row"> 
                            <div className="col-10 columnSetting"><span className="menuHeader">Samosa (2)</span></div><br/>
                            <div className="col-2 price">6€</div>
                            <div className="menuDescrip columnSetting">Lamb or vegetable served with chutney</div>
                        </div> 
                    </div>
                </div> 

                <h2 className="col-12 header1">Large plates</h2>

                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-md-6 columnSetting">

                        <div className="row"> 
                            <div className="col-10 menuHeader columnSetting">Deep dish braised beef & Guiness pie</div><br/>
                            <div className="col-2 price">16€</div>
                        </div>

                        <div className="row"> 
                            <div className="col-10 menuHeader columnSetting">1/2 pounder Irish angus burger</div></div>

                            <div className="row"> 
                                <div className="col-10 menuDescrip columnSetting">Plain</div>
                                <div className="col-2 price">12€</div>
                            </div>

                            <div className="row"> 
                                <div className="col-10 menuDescrip columnSetting">Cheddar</div>
                                <div className="col-2 price">13€</div>
                            </div>

                            <div className="row"> 
                                <div className="col-10 menuDescrip columnSetting">Cheddar & bacon</div>
                                <div className="col-2 price">14€</div>
                            </div>                       
                        

                        <div className="row"> 
                            <div className="col-10 columnSetting menuHeader">Chicken breast burger with herbs & spices</div>
                            <div className="col-2 price">13€</div>
                            <div className="menuDescrip columnSetting"> Topped with hickory smoked bacon & sweet chili sauce</div>
                        </div>                        
                    </div>

                    <div className="col-lg-6 col-sm-6 col-md-6 columnSetting">

                        <div className="row"> 
                            <div className="col-10 columnSetting"><span className="menuHeader">Coconut butter chicken made with 100% free range chicken</span></div><br/>
                            <div className="col-2 price">15€</div>
                            <div className="menuDescrip columnSetting">Served with Christýs famous spicy sauce</div>
                        </div>

                        <div className="row"> 
                            <div className="col-10 columnSetting"><span className="menuHeader">Slow roasted tender full tie of ribs</span></div><br/>
                            <div className="col-2 price">18€</div>
                            <div className="menuDescrip columnSetting">Served with a smokey spiced Bourbon BBQ saucee</div>
                        </div>

                        <div className="row"> 
                            <div className="col-10 columnSetting"><span className="menuHeader">Deep dish Milanese style lasagne</span></div><br/>
                            <div className="col-2 price">15€</div>
                            <div className="menuDescrip columnSetting">Gluten free</div>
                        </div>                        
                    </div>                                                      
                 </div>
            </div>                  

            <div className="underText">Extras: Sauted vegetables of the day: 4.50€ | Home made chunky chips: 3.50€</div>
            <div className="underText1">All main courses dishes are served with home made chunky chips or potato dish of the day!</div>

            <div className="picture"></div>

            <h1 className="header2" id="gallery">Gallery</h1>

                <div className="row divGallery">
                    <img className="galleryImg col-2" src={plate}/>
                    <img className="galleryImg col-2" src={cake}/>
                    <img className="galleryImg col-2" src={burger}/>
                    <img className="galleryImg col-2" src={ribs}/>
                    <img className="galleryImg col-2" src={rollitos}/>
                    <img className="galleryImg col-2" src={mushrooms}/>
                    <img className="galleryImg col-2" src={panncake}/> 
                    <img className="galleryImg col-2" src={cheese}/>
                </div> 

                <div className="row columnSetting">
                    
                    <div className="col-md-6 container"> 
                            <h3 className="col-md-6 header3" id="contact">Where are we?</h3> 
                            <div className="divMap">
                                <Map location={location} zoomLevel={17} />   
                            </div>    
                    </div> 

                    <div className="col-md-6">
                        <h3 className="col-md-6 header3" id="reserve">Reserve your table!</h3>   
                        <ReservasEn/>                   
                        </div>
                </div>

                <div id="contact">
                <Footer></Footer></div>
             

                   


            </div>      </div>       
           
         
  )
    }
}

export default Home;
               


   
      
         



      